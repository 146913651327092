/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BooleanResponse from '../model/BooleanResponse';
import ClassificationWithSpotTypesResponse from '../model/ClassificationWithSpotTypesResponse';
import ImageIEnumerableResponse from '../model/ImageIEnumerableResponse';
import Response from '../model/Response';
import SpotAvailabilityRequest from '../model/SpotAvailabilityRequest';
import SpotRequest from '../model/SpotRequest';
import SpotRequestResponse from '../model/SpotRequestResponse';
import SpotSearchResponse from '../model/SpotSearchResponse';
import SpotTypesResponse from '../model/SpotTypesResponse';
import StringResponse from '../model/StringResponse';

/**
* AdminSpot service.
* @module api/AdminSpotApi
* @version v1
*/
export default class AdminSpotApi {

    /**
    * Constructs a new AdminSpotApi. 
    * @alias module:api/AdminSpotApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotAvailabilityRequest} opts.spotAvailabilityRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotSearchResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotAvailabilityPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['spotAvailabilityRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotAvailabilityPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotSearchResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/availability', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotAvailabilityRequest} opts.spotAvailabilityRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotSearchResponse}
     */
    v1TenantTenantIdAdminAdminSpotAvailabilityPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotAvailabilityPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClassificationWithSpotTypesResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotProductClassificationsGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotProductClassificationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'locationId': opts['locationId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClassificationWithSpotTypesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/productClassifications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClassificationWithSpotTypesResponse}
     */
    v1TenantTenantIdAdminAdminSpotProductClassificationsGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotProductClassificationsGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.spotId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotRequestResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'spotId': opts['spotId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotRequestResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/spot', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.spotId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotRequestResponse}
     */
    v1TenantTenantIdAdminAdminSpotSpotGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotSpotGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Number} spotId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.imageId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDeleteWithHttpInfo(tenantId, spotId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDelete");
      }
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDelete");
      }

      let pathParams = {
        'tenantId': tenantId,
        'spotId': spotId
      };
      let queryParams = {
        'imageId': opts['imageId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/{spotId}/delete_image', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Number} spotId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.imageId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDelete(tenantId, spotId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotSpotIdDeleteImageDeleteWithHttpInfo(tenantId, spotId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.description 
     * @param {File} opts.formFile 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StringResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotIdImagePostWithHttpInfo(spotId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotSpotIdImagePost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotIdImagePost");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
        'title': opts['title'],
        'description': opts['description']
      };
      let headerParams = {
      };
      let formParams = {
        'formFile': opts['formFile']
      };

      let authNames = ['bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = StringResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/{spotId}/image', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.description 
     * @param {File} opts.formFile 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StringResponse}
     */
    v1TenantTenantIdAdminAdminSpotSpotIdImagePost(spotId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotSpotIdImagePostWithHttpInfo(spotId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ImageIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotIdImagesGetWithHttpInfo(spotId, tenantId) {
      let postBody = null;
      // verify the required parameter 'spotId' is set
      if (spotId === undefined || spotId === null) {
        throw new Error("Missing the required parameter 'spotId' when calling v1TenantTenantIdAdminAdminSpotSpotIdImagesGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotIdImagesGet");
      }

      let pathParams = {
        'spotId': spotId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ImageIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/{spotId}/images', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} spotId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ImageIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminSpotSpotIdImagesGet(spotId, tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotSpotIdImagesGetWithHttpInfo(spotId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotRequest} opts.spotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['spotRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/spot', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotRequest} opts.spotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminSpotSpotPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotSpotPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotRequest} opts.spotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['spotRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/spot', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/SpotRequest} opts.spotRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminSpotSpotPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminSpotSpotPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SpotTypesResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminSpotSpotTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminSpotSpotTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SpotTypesResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminSpot/spotTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SpotTypesResponse}
     */
    v1TenantTenantIdAdminAdminSpotSpotTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminSpotSpotTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
