/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductClassAttributeStreamlined from './ProductClassAttributeStreamlined';
import ProductClassification from './ProductClassification';
import SpotEquipmentType from './SpotEquipmentType';
import SpotSpotType from './SpotSpotType';

/**
 * The SpotRequest model module.
 * @module model/SpotRequest
 * @version v1
 */
class SpotRequest {
    /**
     * Constructs a new <code>SpotRequest</code>.
     * @alias module:model/SpotRequest
     */
    constructor() { 
        
        SpotRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SpotRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SpotRequest} obj Optional instance to populate.
     * @return {module:model/SpotRequest} The populated <code>SpotRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SpotRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('areaId')) {
                obj['areaId'] = ApiClient.convertToType(data['areaId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productClass')) {
                obj['productClass'] = ProductClassification.constructFromObject(data['productClass']);
            }
            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = ApiClient.convertToType(data['attributes'], [ProductClassAttributeStreamlined]);
            }
            if (data.hasOwnProperty('spotSpotTypes')) {
                obj['spotSpotTypes'] = ApiClient.convertToType(data['spotSpotTypes'], [SpotSpotType]);
            }
            if (data.hasOwnProperty('spotEquipmentTypes')) {
                obj['spotEquipmentTypes'] = ApiClient.convertToType(data['spotEquipmentTypes'], [SpotEquipmentType]);
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('featuredImageUrl')) {
                obj['featuredImageUrl'] = ApiClient.convertToType(data['featuredImageUrl'], 'String');
            }
            if (data.hasOwnProperty('showAsWalkIn')) {
                obj['showAsWalkIn'] = ApiClient.convertToType(data['showAsWalkIn'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
SpotRequest.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
SpotRequest.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
SpotRequest.prototype['name'] = undefined;

/**
 * @member {Number} locationId
 */
SpotRequest.prototype['locationId'] = undefined;

/**
 * @member {Number} latitude
 */
SpotRequest.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
SpotRequest.prototype['longitude'] = undefined;

/**
 * @member {Number} areaId
 */
SpotRequest.prototype['areaId'] = undefined;

/**
 * @member {Number} loopId
 */
SpotRequest.prototype['loopId'] = undefined;

/**
 * @member {Number} productId
 */
SpotRequest.prototype['productId'] = undefined;

/**
 * @member {module:model/ProductClassification} productClass
 */
SpotRequest.prototype['productClass'] = undefined;

/**
 * @member {Array.<module:model/ProductClassAttributeStreamlined>} attributes
 */
SpotRequest.prototype['attributes'] = undefined;

/**
 * @member {Array.<module:model/SpotSpotType>} spotSpotTypes
 */
SpotRequest.prototype['spotSpotTypes'] = undefined;

/**
 * @member {Array.<module:model/SpotEquipmentType>} spotEquipmentTypes
 */
SpotRequest.prototype['spotEquipmentTypes'] = undefined;

/**
 * @member {Number} salesChannelId
 */
SpotRequest.prototype['salesChannelId'] = undefined;

/**
 * @member {String} featuredImageUrl
 */
SpotRequest.prototype['featuredImageUrl'] = undefined;

/**
 * @member {Boolean} showAsWalkIn
 */
SpotRequest.prototype['showAsWalkIn'] = undefined;






export default SpotRequest;

